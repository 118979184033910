<template>
    <div class="mdownload">
        <div class="mdownload_button" @click="toDownload">
            <span>{{ $t('header.download') }}</span>
            <img src="../static/img/mobile-index/download.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        toDownload() {
            const userAgent = navigator.userAgent.toLowerCase();
            if (/android/.test(userAgent)) {
                // 手机是安卓设备
                console.log("安卓");
                const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                    }${'/android'}`;
                this.$router.push({
                    path: path,
                });
            } else if (/iphone|ipad|ipod/.test(userAgent)) {
                // 手机是苹果设备
                console.log("苹果");
                const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                    }${'/ios'}`;
                this.$router.push({
                    path: path,
                });
            } else {
                // 其他设备
                console.log("其他设备");
            }
        }
    },
}
</script>
<style lang="less" scoped>
.mdownload {
    width: 100%;
    background-color: black;
    padding: 40px 0px;
    border-bottom: 1px solid #3F484D;

    &_button {
        margin: auto;
        width: 256px;
        height: 49px;
        background: #0081FF;
        box-shadow: 20px 24px 30px 0px rgba(71, 108, 255, 0.2);
        border-radius: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 24px;
        }

        img {
            width: 16px;
            height: 16px;
            margin-left: 7px;
        }
    }
}
</style> 