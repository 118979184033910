<template>
  <t-dialog
    width="496"
    :visible="indexdialogvisible"
    :header="false"
    :footer="false"
    :confirm-btn="null"
    :cancelBtn="null"
    :closeBtn="false"
    placement="center"
    :close-on-overlay-click="false"
    :destroyOnClose="true"
  >
    <div class="indexbackground">
      <div class="title">{{ $t("index1.hysy") }}</div>
      <div class="text">
        <div>
          {{ $t("index1.hyy") }}
        </div>
        <div>{{ $t("index1.hyytext1") }}</div>
        <div>{{ $t("index1.hyytext2") }}</div>
        <div>{{ $t("index1.hyytext3") }}</div>
      </div>

      <div class="telephone1">
        <img src="@/assets/images/tel.png" class="tel" alt="" />{{
          $t("index1.lxfs")
        }}
        <a class="telephone" href="https://t.me/FFSAY_Sale" target="_blank"
          >@FFSAY_Sale</a
        >
      </div>

      <!-- <div class="line"></div> -->
      <img src="@/assets/images/footerlogo.png" class="logo" alt="" />
      <img
        src="@/assets/images/pc-close.png"
        @click="indexdialogClose"
        class="close"
        alt=""
      />
    </div>
  </t-dialog>
</template>
<script>
const close = require("@/assets/images/pc-close.png");

export default {
  data() {
    return {
      indexdialogvisible: false,
    };
  },
  mounted() {
    // this.indexdialogvisible = false;
  },
  methods: {
    open() {
      this.indexdialogvisible = true;
    },
    indexdialogClose() {
      this.indexdialogvisible = false;
    },
    closeBtn() {
      return <img src={close} />;
    },
  },
};
</script>
<style lang="less" scoped>
.indexbackground {
  // width: 100%;
  //   position: absolute;
  padding-left: 24px;
  padding-top: 38px;
  padding-bottom: 38px;
  padding-right: 16px;
  //   border-radius: 8px;
  //   height: 283px;
}

/deep/.t-dialog {
  border: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
/deep/.t-dialog--default {
  // padding-left: 24px;
  //   padding-top: 32px;
  //   padding-bottom: 32px;
  //   padding-right: 16px;
  padding: 0;
  background: url("../assets/images/pc-big.png") no-repeat;
  background-size: 100% 100%;
}
/deep/.t-dialog__body {
  padding: 0;
  border-radius: 8px;
}
.title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.text {
  // padding-left:36px;
  font-size: 14px;
  font-weight: 400;
  color: #4c628e;
  line-height: 23px;
  padding-top: 18px;
  padding-bottom: 22px;
  max-width: 260px;
}
.telephone {
  // padding-left:36px;
  font-size: 15px;
  font-weight: 600;
  color: #0081ff;
  line-height: 22px;
  text-decoration: none;
  border-bottom: 1px solid #0081ff;
}
.telephone1 {
  font-size: 15px;
  font-weight: 600;
  color: #08265e;
  line-height: 22px;
  margin-bottom: 33px;
  display: flex;
  align-items: center;
}
.line {
  // margin-left:36px;
  width: 20px;
  height: 2px;
  background: #08265e;
  border-radius: 2px;
}
.logo {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.tel{
  width: 24px;height:24px;
  vertical-align: middle;

}
.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style> 