<template>
    <div class="mfooter">
        <div class="mfooter_iconbox">
            <div class="mfooter_iconbox_box">
                <div class="mfooter_iconbox_box_item">
                    <img src="../static/img/footer/twiter.svg" alt="">
                </div>
                <div class="mfooter_iconbox_box_item">
                    <img src="../static/img/footer/instagram.svg" alt="">
                </div>
                <div class="mfooter_iconbox_box_item">
                    <img src="../static/img/footer/viedo.svg" alt="">
                </div>
                <div class="mfooter_iconbox_box_item">
                    <img src="../static/img/footer/facebook.svg" alt="">
                </div>
            </div>
        </div>
        <div class="mfooter_bottombox">
            <div class="mfooter_bottombox_box">
                <div class="mfooter_bottombox_box_left">
                    <img src="../static/img/index/FFSAY-logo-w.svg" alt="">
                    <!-- <div class="text_box">
                        <div class="text">{{ $t('footer.gnjs') }}</div>
                        <div class="title" @click="toGN">{{ $t('footer.gn') }}</div>
                    </div> -->
                    <div class="text_box" style="margin-top: 42px;">
                        <div class="text">{{ $t('footer.wmss') }}</div>
                        <div class="title" @click="toAbout">{{ $t('footer.gywm') }}</div>
                        <div class="title" @click="toRecruit">{{ $t('footer.zpxx') }}</div>
                    </div>
                </div>
                <div class="mfooter_bottombox_box_right">
                    <div class="mfooter_bottombox_box_right_change" @click="changelanguage" ref="change">
                        <div class="change_text">{{ $t('app.' + $i18n.locale) }}</div>
                        <img src="../static/img/mobile-footer/down.png" alt="">
                        <div class="language_fix" v-if="showlanguage">
                            <nuxt-link @click.native="switchLang(locale.code)" :to="switchLocalePath(locale.code)"
                                v-for="(locale, i) in showLocales" :key="i">
                                {{ $t('app.' + locale.code) }}
                            </nuxt-link>

                        </div>
                    </div>
                    <div class="text_box">
                        <div class="text">{{ $t('footer.xybz') }}</div>
                        <div class="title" @click="toContact">{{ $t('footer.lxwm') }}</div>
                    </div>
                    <!-- <div class="text_box" style="margin-top: 48px;">
                        <div class="text">{{ $t('footer.syggs') }}</div>
                        <div class="title" @click="toIOS">{{ $t('footer.iPhone') }}</div>
                        <div class="title" @click="toAndroid">{{ $t('footer.Android') }}</div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="mfooter_bbox">
         © FFSAY LLC
        </div>
    </div>
</template>
<script>
export default {
    scrollToTop: true,
    data() {
        return {
            language: 'zh',
            showlanguage: false,
        }
    },
    mounted() {
        console.log(this.$route.path);
        if (this.$route.path.includes('ja')) {
            this.language = 'ja'
        } else {
            this.language = 'zh'
        }
        const mfooter = this.$refs.change;
        document.addEventListener('click', (event) => {
            if (!mfooter.contains(event.target)) {
                this.showlanguage = false
            }
        });
    },
    watch: {
    $route(to) {
      let rule = to.path;
      console.log(rule);
      if (rule.includes('ja')) {
        this.language = 'ja'
      } else {
        this.language = 'zh'
      }
    },
  },
    methods: {
        toIOS() {
            const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                    }${'/ios'}`;
                this.$router.push({
                    path: path,
                });
        },
        toAndroid() {
            const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                    }${'/android'}`;
                this.$router.push({
                    path: path,
                });
        },
        toContact() {
            const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                }${'/contact'}`;
            this.$router.push({
                path: path,
            });
        },
        toRecruit() {
            const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                }${'/recruit'}`;
            this.$router.push({
                path: path,
            });
        },
        toAbout() {
            const path = `${this.$i18n.locale === 'zh' ? "" : "/" + this.$i18n.locale
                }${'/about'}`;
            this.$router.push({
                path: path,
            });
        },
        toGN() {
            this.$emit('toGN', '1')
        },
        changelanguage() {
              this.showlanguage = !this.showlanguage
        },
        switchLang(lang) {
            console.log(222);
            this.$store.commit("SET_LANG", lang);
            console.log(this.$store.state);
            this.language = lang
            this.showlanguage = false
        },
    },
    computed: {
        showLocales() {
            return this.$i18n.locales;
        },
    }
}
</script>
<style lang="less" scoped>
.mfooter {
    width: 100%;
    height: 410px;
    background-color: black;

    &_iconbox {
        width: 100%;
        border-bottom: 1px solid #3F484D;
        height: 97px;

        &_box {
            width: 375px;
            margin: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            &_item {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: 1px solid #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    &_bottombox {
        width: 100%;
        height: 250px;
        border-bottom: 1px solid #3F484D;

        &_box {
            width: 336px;
            margin: auto;
            height: 100%;
            padding-left: 24px;
            padding-right: 15px;
            display: flex;
            justify-content: space-between;

            &_left {
                width: 126px;
                margin-top: 28px;

                img {
                    width: 126px;
                    height: 32px;
                }

                .text_box {
                    width: 100%;
                    margin-top: 42px;

                    .text {
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }

                    .title {
                        margin-top: 24px;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 24px;
                    }
                }
            }

            &_right {
                margin-left: 65px;
                margin-top: 24px;

                &_change {
                    width: 144px;
                    height: 40px;
                    border-radius: 29px;
                    border: 1px solid #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    .change_text {
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 22px;
                        margin-left: 24px;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 24px;
                    }

                    .language_fix {
                        position: absolute;
                        left: 0;
                        top: 45px;
                        background-color: #fff;
                        border-radius: 12px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 16px;
                        z-index: 300;
                        a { 
                            font-size: 14px;
                            color: #0A1D4C;
                            text-decoration: none;
                            margin-left: 20px;
                            margin-top: 16px;
                        }
                    }
                }

                .text_box {
                    width: 100%;
                    margin-top: 42px;

                    .text {
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }

                    .title {
                        margin-top: 24px;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    &_bbox {
        font-size: 12px;
        color: #FFFFFF;
        height: 100%;
        display: flex;
        height: 62px;
        align-items: center;
        justify-content: center;
    }
}</style>